import service from '@/utils/request'


export function Post(url: string, params: object) {
    return service({
        url: url,
        method: 'post',
        data: params
    });
}

export function PostJson(url: string, params: object) {
    return service({
        url: url,
        method: 'post-json',
        data: params,
    })
}

export function Put(url: string, params: object) {
    return service({
        url: url,
        method: 'put',
        data: params
    })
}

export function Get(url: string, params: object) {
    return service({
        url: url,
        method: 'get',
        params: params
    })
}

export function Delete(url: string, params: object) {
    return service({
        url: url,
        method: 'delete',
        params: params,
    })
}



