import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import AdminLayout from '@/views/admin/layout/index.vue'
// @ts-ignore
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Index',
        meta: {title: '首页'},
        component: () => import('../views/index/index.vue')
    },
    {
        path: '/disease',
        name: 'Disease',
        meta: {title: '防疫平台'},
        component: () => import('@/views/disease/index.vue')
    },
    {
        path: '/technology',
        name: 'Technology',
        meta: {title: '技术平台'},
        component: () => import('@/views/technology/index.vue')
    },
    {
        path: '/supply',
        name: 'Supply',
        meta: {title: '供应平台'},
        component: () => import('../views/supply/index.vue')
    },
    {
        path: '/carbon',
        name: 'Carbon',
        meta: {title: '牛只碳足迹'},
        component: () => import('../views/carbon/index.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {title: '登录'},
        component: () => import('../views/login/index.vue')
    },
    {
        path: '/admin',
        name: 'Admin',
        meta: {title: '数据管理'},
        component: AdminLayout,
        children: [
            {
                path: '',
                name: 'AdminIndex',
                meta: {title: '管理首页', admin: true},
                component: () => import('@/views/admin/index.vue')
            },
            {
                path: 'factory',
                name: 'AdminFactory',
                meta: {title: '牧场管理', admin: true},
                component: () => import('@/views/admin/factory/index.vue')
            },
            {
                path: 'users',
                name: 'AdminUsers',
                meta: {title: '用户管理', admin: true},
                component: () => import('@/views/admin/users/index.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
