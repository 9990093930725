import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import settings from '@/utils/settings'
import router from "../router";
import store from "../store";
import {ElMessageBox} from "element-plus";

const qs = require('qs')
const service = axios.create({
    baseURL: settings.BASE_URL, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: settings.API_TIME_OUT, // request timeout
    responseType: 'json',
    withCredentials: false
})

const formatUrl = (url: string, data: object) => {
    if (url.indexOf('${') > -1) {
        let u = url
        const t = /\$\{[^}]+}/g
        const m = u.match(t)
        const temp = data
        if (m != null && m.length > 0) {
            for (let i = 0; i < m.length; i++) {
                const k = m[i].substring(2, m[i].length - 1)
                u = u.replace(new RegExp(m[i], 'gi'), data[k])
                u = u.replace(m[i], temp[k])
                delete data[k]
            }
        }
        url = u

    }

    const result: object = {url: url, data: data}
    return result
}


// request interceptor
service.interceptors.request.use(
    config => {
        //if(config.url.substring(0, 1) != '/'){
        //  config.url = '/'+config.url
        //}
        if (config.url.startsWith('http:') || config.url.startsWith('https')) {
            config.baseURL = null
        } else {
            const fmtUrl: object = formatUrl(config.url, config.params)
            // @ts-ignore
            config.url = fmtUrl.url.replace('//', '/')
            // @ts-ignore
            config.params = fmtUrl.data || {}
            //console.info('req url:', config.url)
            //console.info('config:', config)
        }
        // @ts-ignore

        // do something before request is sent
        if (sessionStorage.getItem('AuthLogin') && !config.headers[settings.AUTH_TOKEN_HEADER]) {
            config.headers[settings.AUTH_TOKEN_HEADER] = sessionStorage.getItem('AuthLogin')
        }


        // service.defaults.baseURL = 'https://www.example.com/api';

        // @ts-ignore
        if (config.method.toLowerCase() === 'put' || config.method.toLowerCase().endsWith('-json')) {
            if (config.method.toLowerCase() === 'post-json') {
                config.method = 'POST'
            }
            config.headers['Content-Type'] = 'application/json;charset=utf-8'
        } else {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
            config.data = qs.stringify(config.data, {arrayFormat: 'repeat'})
        }
        //console.info('content type', config.ContentType)
        // @ts-ignore


        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor

service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
        // @ts-ignore
    (response) => {
        const ResultData = {} as const
        const res = response.data
        if (res && res.success) {
            return Promise.resolve(res.data)
        } else {
            if (settings.CODE_FOR_LOGIN && settings.CODE_FOR_LOGIN.includes(res.code)) {
                store.dispatch('resetSession')
                ElMessageBox.alert('登录超时')
                router.push('/')
                //location.reload()
            }
            return Promise.reject(res.message || 'Api错误')
        }
        /*const globalSetting = window.globalSetting || {
            redirectLogin: true,
            errorHandler(res) {
                Message({
                    message: res.message || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                });
            }
        }
        if (!res || !res.success) {
            if (settings.codeForLogin && settings.codeForLogin.indexOf(res.code) > -1 && globalSetting.redirectLogin) {
                MessageBox.confirm('用户登录超时，请重新登录', '登录超时', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload()
                    })
                })
            } else {
                globalSetting.errorHandler(res)
                return Promise.reject(new Error(res.message || 'Error'))
                //return Promise.reject(response)
            }

        } else {
            //eturn res
            let resp = {}
            if (res[settings.respBodyName]) {
                resp.result = res[settings.respBodyName]
            }
            if (res[settings.respPageName]) {
                resp.page = res[settings.respPageName]
            }
            return Promise.resolve(resp)
        }*/
    },
    error => {
        console.error('err' + error) // for debug
        //throw error
        return Promise.reject(error)
    }
)

export default service
