<template>
  <el-container class="admin-box">
    <el-aside class="side-bar" :width="collapsed ? '60px' : '300px'">
      <div class="logo">
        数据管理
      </div>
      <el-menu
          active-text-color="#ffd04b"
          background-color="#383d42"
          class="el-menu-vertical-demo"
          text-color="#fff"
          :collapse="collapsed"
      >
        <el-sub-menu index="factory">
          <template #title>
            <el-icon>
              <location/>
            </el-icon>
            <span>牧场管理</span>
          </template>
          <el-menu-item index="factory-info">牧场信息</el-menu-item>
<!--          <el-menu-item-group title="Group Two">
            <el-menu-item index="1-3">item three</el-menu-item>
          </el-menu-item-group>
          <el-sub-menu index="1-4">
            <template #title>item four</template>
            <el-menu-item index="1-4-1">item one</el-menu-item>
          </el-sub-menu>-->
        </el-sub-menu>
        <el-menu-item index="2">
          <el-icon>
            <icon-menu/>
          </el-icon>
          <span>Navigator Two</span>
        </el-menu-item>
        <el-menu-item index="3" disabled>
          <el-icon>
            <document/>
          </el-icon>
          <span>Navigator Three</span>
        </el-menu-item>
        <el-menu-item index="4">
          <el-icon>
            <setting/>
          </el-icon>
          <span>Navigator Four</span>
        </el-menu-item>
      </el-menu>
      <!--      <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
              <a-menu-item key="factory">
                <user-outlined/>
                <span><router-link to="/admin/factory">牧场管理</router-link></span>
              </a-menu-item>
              <a-menu-item key="users">
                <user-outlined/>
                <span><router-link to="/admin/users">用户管理</router-link></span>
              </a-menu-item>
              &lt;!&ndash;        <a-menu-item key="2">
                        <video-camera-outlined/>
                        <span>nav 2</span>
                      </a-menu-item>
                      <a-menu-item key="3">
                        <upload-outlined/>
                        <span>nav 3</span>
                      </a-menu-item>&ndash;&gt;
            </a-menu>-->
    </el-aside>
    <el-container>
      <el-header class="admin-header flex-nowrap" style="background: #fff; padding: 0">
        <div class="collapse-icon">
          <Expand v-if="collapsed" @click="() => (collapsed = !collapsed)" color="#666"/>
          <Fold v-else @click="() => (collapsed = !collapsed)" color="#666"/>
          <!--          <menu-unfold-outlined
                        v-if="collapsed"
                        class="trigger"
                        @click="() => (collapsed = !collapsed)"
                    />
                    <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)"/>-->
        </div>
        <div class="admin-bread">
          <el-breadcrumb :separator-icon="ArrowRight">
            <el-breadcrumb-item><a href="">管理首页</a></el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="admin-top-menu d-flex">
          <div>
            <el-dropdown>
              <el-avatar size="large" :style="{ backgroundColor: '#f56a00', verticalAlign: 'middle' }" :gap="32">
                <template #icon>
                </template>
              </el-avatar>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item key="changepwd">
                    <a href="javascript:void(0)" @click="changePwd">修改密码</a>
                  </el-dropdown-item>
                  <el-dropdown-item key="logout">
                    <a href="javascript:void(0)" @click="logoff">退出登录</a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-main
          :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }"
      >
        <router-view :key="key"/>
      </el-main>
      <el-footer style="text-align: center">
        数字牧业 ©2024 Copyright
      </el-footer>
    </el-container>
  </el-container>
</template>
<script>
import router from "@/router";
import {mapGetters} from "vuex";
import store from "@/store";
import {
  ArrowRight,
  Document,
  Menu as IconMenu,
  Location,
  Setting, Fold, Expand,
} from '@element-plus/icons-vue'

export default {
  name: 'AdminLayout',

  components: {
    Expand,
    Fold,
    Document, IconMenu, Location, Setting, ArrowRight
  },
  data() {
    return {
      userInfo: store.state.userInfo,
      selectedKeys: ['1'],
      collapsed: false,
      routers: [],
      routerPath: []
    }
  },
  computed: {
    key() {
      return this.$route.path
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loadRouters()
    },
    /**
     * 读取路由
     */
    loadRouters() {
      let routers = router.getRoutes()
      let adminRoot = routers.find(f => f.name === 'Admin')
      console.info('ROUTER:', adminRoot)
    },
    changePwd() {
      //console.info('changePwd')
    },
    logoff() {
      sessionStorage.clear()
      router.push('/login')
    }
  }
}
</script>
<style lang="scss">
@import "../style/admin.css";

.admin-main {
}
</style>
<style scoped lang="scss">
.logo {
  background: #383d42;
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-weight: bold;
  padding: 0 10px;
}

.admin-box {
  height: 100%;
  background: #ececec;
  color: #0f1325;

  .side-bar {
    background: #383d42;
  }

  .admin-header {
    background: #fff;
    padding: 0;
    align-items: stretch;
    align-content: space-between;
    width: 100%;
    display: flex;
    display: -webkit-flex;

    .collapse-icon {
      margin: 0 5px;
      padding: 15px 0 0 0 ;
      width: 30px;
    }

    .admin-bread {
      padding: 20px 20px 0 10px;
      flex: 1;
    }

    .admin-top-menu {
      margin: 0 20px 0 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}
</style>
