export default {
    DEV_MODE: process.env['NODE_ENV'] === 'development',
    /**
     * Token携带头
     */
    AUTH_TOKEN_HEADER : process.env['VUE_APP_TOKEN_HEADER'],
    /**
     * 未登录状态码
     */
    LOGIN_CODE : process.env['VUE_APP_CODE_FOR_LOGIN'],
    /**
     * 接口地址
     */
    BASE_URL : process.env['VUE_APP_BASE_API'],
    /**
     * Websocket地址
     */
    WEBSOCKET_API : process.env['VUE_APP_WEBSOCKET_API'],

    /**
     * API请求超时时间
     */
    API_TIME_OUT : process.env['VUE_APP_API_TIMEOUT'],

    /**
     * 应用标题
     */
    TITLE : process.env['VUE_APP_TITLE'],

    /**
     * 短标题
     */
    SORT_TITLE : process.env['VUE_APP_SHORT_TITLE'],

    /**
     * 登录超时代码
     */
    CODE_FOR_LOGIN: process.env['VUE_APP_CODE_FOR_LOGIN'],

    /**
     * 天地图Key
     */
    TIANDI_KEY : process.env['VUE_APP_TIANDI_KEY'],

    /**
     * 胶囊接口地址
     */
    CAPSULE_API : process.env['VUE_APP_CAPSULE_API']
}
