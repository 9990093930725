/**
 * session管理工具
 */
export default {
    set(key, val) {
        if (val === null || val === undefined) sessionStorage.removeItem(key)
        sessionStorage.setItem(key, (typeof val) === 'object' ? JSON.stringify(val) : val)
    },
    get(key) {
        const obj = sessionStorage.getItem(key)
        if (obj === null || obj.toLowerCase() === 'null' || obj === 'undefined') return null //
        if ((obj.trim().startsWith('{') && obj.trim().endsWith('}')) || (obj.trim().startsWith('[') && obj.trim().endsWith(']'))) {
            return JSON.parse(obj)
        }
        return obj
    },
    remove(key) {
        sessionStorage.removeItem(key)
    },
    reset() {
        let noFull = sessionStorage.getItem('no-full');
        sessionStorage.clear()
        if (noFull) {
            sessionStorage.setItem('no-full', noFull);
        }
    }
}

