import axios from "axios";
import settings from "./settings";
import service from "./request";

const baseApi = 'http://111.56.227.107:8001/prod-api'

/**
 * 胶囊接口处理
 */
const http = axios.create({
    baseURL: settings.CAPSULE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: settings.API_TIME_OUT, // request timeout
    responseType: 'json',
    withCredentials: false
})
http.interceptors.request.use(
    config => {
        if (config['token']) {
            config.headers['Authorization'] = config['token']
        }
        config.headers['Accept-Language'] = 'zh'
        if(config.method.toLowerCase()==='put'){
            config.headers['Content-Type'] = 'application/json;charset=utf-8'
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)
http.interceptors.response.use(
    (response) => {
        const ResultData = {} as const
        const res = response.data
        return Promise.resolve(res)
    },
    error => {
        console.error('err' + error) // for debug
        //throw error
        return Promise.reject(error)
    }
)

const ApiGet = (url, token, params) => {
    return new Promise((resolve, reject) => {
        http({
            url: url,
            method: 'get',
            params
        }).then(r => {
            resolve(r)
        }).catch(e => {
            console.error('查询接口出错', e)
            reject(e)
        })
    })

}

const ApiPut = (url, params) => {
    return new Promise((resolve, reject) => {
        http({
            url: url,
            method: 'put',
            data: params
        }).then(r => {
            resolve(r)
        }).catch(e => {
            console.error('提交接口出错', e)
            reject(e)
        })
    })

}
/**
 * 获取牧场设备列表
 * @param factoryId
 * @param page
 * @param size
 */
export const getFactoryDevices = (
    factoryId: string,
    page: number,
    size: number
) => {
    return ApiGet('/device/capsule/callList', 'ba453292-2b22-49dc-aa43-da1f3b354771', {
        pageNum: page,
        pageSize: size,
        equipmentId: 2,
        parentId: factoryId
    })
}
/**
 * 取牧场棚舍列表
 * @param factoryId
 */
export const getFactoryDormitories = (
    factoryId: string
) => {
    return ApiGet('/ranching/dormitory/callList', 'cc00654f-a675-436e-b8c1-8019fc6d93eb', {
        parentId: factoryId
    })
}
/**
 * 取牧场牛羊信息
 * @param factoryId
 * @param page
 * @param size
 * @param dormitory -> 棚舍号
 * @param earconNum ->  耳标号
 */
export const getFactoryBreeds = (
    factoryId: string,
    page: number,
    size: number,
    dormitory?: string,
    earconNum?: string,
) => {
    return ApiGet('/ranching/breeds/callList', '34f969fc-bd3c-453d-9f17-151fa9de9dfe', {
        pageNum: page,
        pageSize: size,
        dormitory: dormitory,
        earconNum: earconNum,
        parentId: factoryId
    })
}

/**
 * 查询牧场疾病/发情列表
 * @param factoryId
 * @param type  -> 类型 1-发情 2-疾病
 * @param page
 * @param size
 * @param status -> 查询类型 0-全部 1-待处理
 */
export const getFactoryForecast = (
    factoryId: string,
    type: number,
    page: number,
    size: number,
    status: number
) => {
    return ApiGet('/forecast/warning/callList', 'ba453292-2b22-49dc-aa43-da1f3b354771', {
        pageNum: page,
        pageSize: size,
        status: status,
        queryType: 2,
        parentId: factoryId
    })
}

/**
 * 报警信息查询参数
 * @param startTime 开始时间（yyyy-MM-dd HH:mm:ss）
 * @param endTime 结束时间（yyyy-MM-dd HH:mm:ss）
 * @param status    查询类型0： 1未读 3已读
 * @param parentId  牧场ID
 * @param types 预警类型1健康检查 2发情 3流产风险 4体温升高 5体温下降 6饮水异常 7反刍异常 8胃动量异常 9酸中毒 10减中毒11人工授精 12怀孕检查 13干乳 14待产犊 15调群 16位置异常 17设备异常
 * @param earconNum 耳标
 * @param deviceNum 设备号
 */
export interface WarningCountQuery {
    startTime?: string,  //开始时间（yyyy-MM-dd HH:mm:ss）
    endTime?: string,    //结束时间（yyyy-MM-dd HH:mm:ss）
    status?: number,
    parentId: string,
    types?: number,
    earconNum?: string,
    deviceNum?: string
}

/**
 * 查询报警信息
 * @param params
 */
export const getFactoryWarningCount = (
    params: WarningCountQuery
) => {
    return ApiGet('/ranching/breeds/selectEmWarningInfoCount', null, params)
}

/**
 *
 */
export interface WarningQuery extends WarningCountQuery {
    pageNum: number,
    pageSize: number
}

/**
 * 查询报警明细列表
 * @param params
 */
export const getWarningDetail = (
    params: WarningQuery
) => {
    return ApiGet('/ranching/breeds/selectEmWarningInfoList', null, params)
}

/**
 * 查询所有状态参数
 * @param pageNum
 * @param pageSize
 * @param startTime
 * @param endTime
 * @param deptId
 * @param earconNum
 */
interface AllStatusQuery {
    pageNum: number,
    pageSize: number,
    startTime: string,  //开始时间（yyyy-MM-dd）
    endTime: string,    //结束时间（yyyy-MM-dd）
    deptId: string,
    earconNum: string
}

/**
 * 查询牧场牛/羊温度、胃动量、饮水、姿态列表
 * @param params
 */
export const getAllStatus = (
    params: AllStatusQuery
) => {
    return ApiGet('/ranching/breeds/selectEmWarningInfoList',
        '34f969fc-bd3c-453d-9f17-151fa9de9dfe',
        Object.assign(params, {queryType: 14, timeRangeType: 0}))
}


/**
 * 状态查询参数
 * @param earTag -> 耳标号
 * @param deviceNumber ->   设备号
 * @param startTime -> 开始时间（yyyy-MM-dd）
 * @param endTime ->    结束时间（yyyy-MM-dd）
 * @param queryType ->  1-温度 6-胃动量 9-饮水 13-姿态
 */
interface StatusQuery {
    earTag: string,
    deviceNumber: string,
    startTime?: string,
    endTime?: string,
    queryType: number
}

/**
 * 查询牧场牛/羊温度、胃动量、饮水、姿态
 * @param params
 */
export const getStatus = (
    params: StatusQuery
) => {
    return ApiGet('/beidou/behavior/details',
        'de23201e-2ad8-4282-9b4b-9ea8403f4ab5',
        params
    )
}

/**
 * 修改状态
 * @param ids
 */
export const updateWarningStatus = (
    ids
) => {
    return ApiPut('/ranching/breeds/updateEmWarningInfo', {
        ids: ids,
        status: 3
    })
}

